import { Flex, Text, IconButton, AddIcon, MinusIcon, useModal, Skeleton, Box } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'
import { getBalanceNumber } from 'utils/formatBalance'
import { DeserializedPool } from 'state/types'
// import { usePriceCakeBusd } from 'state/farms/hooks'
import { useVaultPoolByKey } from 'state/pools/hooks'
import { getAddress } from 'state/user/hooks'
import Balance from 'components/Balance'

import { testnetTokens } from 'config/constants/tokens'
import { getLpContract } from 'utils/contractHelpers'
import { rp1PriceUsd } from 'state/farms/hooks'
import NotEnoughTokensModal from '../../PoolCard/Modals/NotEnoughTokensModal'
import VaultStakeModal from '../VaultStakeModal'
import ConvertToLock from '../../LockedPool/Common/ConvertToLock'

interface HasStakeActionProps {
  pool: DeserializedPool
  stakingTokenBalance: BigNumber
  performanceFee: number
}

const HasSharesActions: React.FC<HasStakeActionProps> = ({ pool, stakingTokenBalance, performanceFee }) => {
  const {
    userData: {
      balance: { cakeAsBigNumber, cakeAsNumberBalance },
    },
  } = useVaultPoolByKey(pool.vaultKey)

  const { stakingToken } = pool
  const [cakePriceBusd, setCakePriceUsd] = useState(new BigNumber(0))
  useEffect(() => {
    // const pairAddress = getAddress(testnetTokens.rp1, testnetTokens.usdt)
    // const contract = getLpContract(pairAddress)
    // contract.getReserves().then((data) => {
    // const reserve1 = data._reserve1.toString()
    // const reserve0 = data._reserve0.toString()
    // const price = new BigNumber(reserve1).div(new BigNumber(reserve0))
    const price = rp1PriceUsd()
    setCakePriceUsd(new BigNumber(price))
    // return price
    // })
  }, [])

  // const cakePriceBusd = usePriceCakeBusd()
  const stakedDollarValue = cakePriceBusd.gt(0)
    ? getBalanceNumber(cakeAsBigNumber.multipliedBy(cakePriceBusd), stakingToken.decimals)
    : 0

  const [onPresentTokenRequired] = useModal(<NotEnoughTokensModal tokenSymbol={stakingToken.symbol} />)
  const [onPresentStake] = useModal(
    <VaultStakeModal stakingMax={stakingTokenBalance} performanceFee={performanceFee} pool={pool} />,
  )
  const [onPresentUnstake] = useModal(
    <VaultStakeModal stakingMax={cakeAsBigNumber} pool={pool} isRemovingStake />,
    true,
    true,
    'withdraw-vault',
  )

  return (
    <>
      <Flex mb="16px" justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <Balance fontSize="20px" bold value={cakeAsNumberBalance} decimals={5} />
          <Text as={Flex} fontSize="12px" color="textSubtle" flexWrap="wrap">
            {cakePriceBusd.gt(0) ? (
              <Balance
                value={stakedDollarValue}
                fontSize="12px"
                color="textSubtle"
                decimals={2}
                prefix="~"
                unit=" USD"
              />
            ) : (
              <Skeleton mt="1px" height={16} width={64} />
            )}
          </Text>
        </Flex>
        <Flex>
          <IconButton variant="secondary" onClick={onPresentUnstake} mr="6px">
            <MinusIcon color="primary" width="24px" />
          </IconButton>
          <IconButton variant="secondary" onClick={stakingTokenBalance.gt(0) ? onPresentStake : onPresentTokenRequired}>
            <AddIcon color="primary" width="24px" height="24px" />
          </IconButton>
        </Flex>
      </Flex>
      <Box mb="16px">
        <ConvertToLock stakingToken={stakingToken} currentStakedAmount={cakeAsNumberBalance} />
      </Box>
    </>
  )
}

export default HasSharesActions
